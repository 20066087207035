var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDropzone } from 'react-dropzone';
// @mui
import { styled, alpha } from '@mui/material/styles';
// components
import Iconify from '../iconify';
// ----------------------------------------------------------------------
var StyledDropZone = styled('div')(function (_a) {
    var theme = _a.theme;
    return ({
        width: 64,
        height: 64,
        fontSize: 24,
        display: 'flex',
        flexShrink: 0,
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(0.5),
        color: theme.palette.text.disabled,
        borderRadius: theme.shape.borderRadius,
        border: "dashed 1px ".concat(theme.palette.divider),
        backgroundColor: alpha(theme.palette.grey[500], 0.08),
        '&:hover': {
            opacity: 0.72,
        },
    });
});
// ----------------------------------------------------------------------
export default function UploadBox(_a) {
    var placeholder = _a.placeholder, error = _a.error, disabled = _a.disabled, sx = _a.sx, other = __rest(_a, ["placeholder", "error", "disabled", "sx"]);
    var _b = useDropzone(__assign({ disabled: disabled }, other)), getRootProps = _b.getRootProps, getInputProps = _b.getInputProps, isDragActive = _b.isDragActive, isDragReject = _b.isDragReject;
    var isError = isDragReject || error;
    return (_jsxs(StyledDropZone, __assign({}, getRootProps(), { sx: __assign(__assign(__assign(__assign({}, (isDragActive && {
            opacity: 0.72,
        })), (isError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
        })), (disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
        })), sx) }, { children: [_jsx("input", __assign({}, getInputProps())), placeholder || _jsx(Iconify, { icon: "eva:cloud-upload-fill", width: 28 })] })));
}
